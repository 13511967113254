import { useState, React, createContext } from "react";


export const AdminContext = createContext();

// Create an exportable consumer that can be injected into components
export const AdminConsumer = AdminContext.Consumer
// Create the provider using a traditional React.Component class

const AdminProvider = ({ children }) => {
    let [collectionPages, setCollectionPages] = useState(null);
    let [savingHistory, setSavingHistory] = useState([]);
    let [userDetail, setUserDetail] = useState({});
    let [userId, setUserId] = useState("");
    let [transactions, setTransactions] = useState(null);
    let [pageTransactions, setPageTransactions] = useState(null);


    return (
        // value prop is where we define what values 
        // that are accessible to consumer components
        <AdminContext.Provider value={{
            collectionPages, setCollectionPages,
            transactions, setTransactions,
            pageTransactions, setPageTransactions,
            userDetail, setUserDetail,
            userId, setUserId,

        }}>
            {children}
        </AdminContext.Provider>
    )
}
export default AdminProvider
