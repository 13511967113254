import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "../constants/Constants";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

let CollectionPagePillComponent = ({ data, index , moreFn}) => {
    let { reference, firstName, lastName, emailAddress, mobileNumber, amount,pageSlugId, amountCollected, verified } = data;
    return <>
        <div className="rounded-xl bg-rouzo-base-color shadow-2xl flex flex-row justify-between gap-4 mb-3">
            <div className="p-4 flex flex-row gap-4 justify-between flex-grow items-center">
                <div className="flex flex-row items-center gap-2 ">
                    <div>
                        <p className="text-xs text-white">#{index}</p>
                    </div>
                    <div className="bg-white rounded-xl py-0 px-4">
                        <p className="font-bold text-rouzo-deep-blue">{firstName} {lastName}</p>

                    </div>
                </div>
                <div className="flex text-xs underline underline-offset-2 text-white flex-col gap-2">
                    <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                    <a href={`tel:${mobileNumber}`}>{mobileNumber}</a>
                </div>
                {
                    verified ?
                        <div className="rounded-full gap-2 bg-white flex flex-row items-center  p-1 border border-rouzo-green-light">
                            <FontAwesomeIcon className="text-rouzo-green-light border border-2 border-rouzo-green-light rounded-full animate-pulse text-xs" icon={faCircle}></FontAwesomeIcon>
                            <p className="font-bold text-xs text-rouzo-green-light">active</p>
                        </div>
                        :
                        <div className="rounded-full gap-2 bg-white flex flex-row items-center p-1 border border-orange-300">
                            <FontAwesomeIcon className="text-orange-300 border border-2 border-orange-500 rounded-full animate-pulse text-xs" icon={faCircle}></FontAwesomeIcon>
                            <p className="font-bold text-xs text-orange-300">pending</p>
                        </div>
                }
                <div className="">

                </div>
                <div className="flex flex-col px-1 bg-rouzo-green rounded-xl ">
                    <p className="px-1 text-black text-lg font-extrabold ">{Constants.nairaString} {amount}</p>
                    <p className="px-1 bg-white rounded-xl text-black text-xs font-bold ">{Constants.nairaString} {amountCollected}</p>
                </div>
                <a href={`/p/${pageSlugId}`}>Link</a>

            </div>
            <div onClick={() => moreFn(pageSlugId)} className="bg-rouzo-green-light p-0 items-center text-xs flex font-bold lota-font hover:underline hover:cursor-pointer hover:underline-offset-2 rounded-r-xl ">
                <p className="rotate-90">More</p>
            </div>
        </div>
    </>
}

export default CollectionPagePillComponent;