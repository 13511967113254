import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CollectionPageService from "../api/services/CollectionPageService";
import { toast } from "react-toastify";
import CollectionPageComponent from "../components/CollectionPageComponent";


let CollectionPage = () => {

    let { pageSlugId } = useParams();
    let [collectionPage, setCollectionPage] = useState(null);

    let [duration, setDuration] = useState(3);
    let [interval, setInterval] = useState("everyMonth");
    let [firstName, setFirstName] = useState(null);
    let [lastName, setLastName] = useState(null);
    let [emailAddress, setEmailAddress] = useState(null);
    let [amount, setAmount] = useState(0);
    let [mobileNumber, setMobileNumber] = useState(null);
    let [verified, setVerified] = useState(false);
    let [loading, setLoading] = useState(true);

    let fetchCollectionPage = async () => {
        try {
            let page = await CollectionPageService.one(pageSlugId);
            console.log(page);
            if (page.type == "success") {
                let { amount, collectionInterval, firstName, lastName, emailAddress, mobileNumber, verified } = page.data;
                console.log(page.data)
                setCollectionPage(page.data);
                setDuration(duration);
                setAmount(amount);
                setEmailAddress(emailAddress);
                setFirstName(firstName);
                setLastName(lastName);
                setInterval(collectionInterval);
                setMobileNumber(mobileNumber);
                setVerified(verified);

            }
            else if (page.type == "error") {
                toast.error(page.message);
            }
        }
        catch (e) {
            console.log(e);
            toast.error(e);
        }
    }
    useEffect(() => {
        if (!collectionPage) {
            setLoading(true);
            fetchCollectionPage();

        }
        else {
            setLoading(false);
        }
    }, [collectionPage]);

    return <>
        <div>
            <CollectionPageComponent
                firstName={firstName}
                lastName={lastName}
                amount={amount}
                mobileNumber={mobileNumber}
                emailAddress={emailAddress}
                pageSlugId={pageSlugId}
                verified={verified}
            ></CollectionPageComponent>
        </div>
    </>
}

export default CollectionPage;