import Constants from "../../constants/Constants";
import axios from "../axios";
const CollectionPageService = {
    create: async (data) => {
        let url = Constants.PAGE.CREATE;
        let req = await axios.post(url, data);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    delete: async (pageId) => {
        //console.log(reference);
        let url = Constants.PAGE.DELETE(pageId);
        let req = await axios.delete(url, {});
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    all: async () => {
        //console.log(reference);
        let url = Constants.PAGE.ALL;
        let req = await axios.get(url, {});
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    one: async (pageId) => {
        //console.log(reference);
        let url = Constants.PAGE.ONE(pageId);
        let req = await axios.get(url, {});
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    update: async (pageId) => {
        //console.log(reference);
        let url = Constants.PAGE.UPDATE(pageId);
        let req = await axios.patch(url, {});
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    transaction: {
        page: {
            all: async (pageSlugId) => {
                let url = Constants.PAGE.TRANSACTIONS.ALL(pageSlugId);
                let req = await axios.get(url, {});
                if (req.data) {
                    let { type, data } = req.data;
                    console.log(type)
                }
                return req.data;
            }
        }
    },
    manualCharge: async (pageSlugId, collectionAmount) => {
        let url = Constants.PAGE.MANUAL_CHARGE;
        let req = await axios.post(url, {
            pageSlugId: pageSlugId,
            collectionAmount: collectionAmount
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    updateCollectionTime : async ( pageSlugId , collectionTime) => {
        let url = Constants.PAGE.UPDATE_COLLECTION_TIME(pageSlugId);
        let req = await axios.patch(url, {
            pageSlugId: pageSlugId,
            nextCollectionTime : collectionTime
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    }
};

export default CollectionPageService;
