import axios from "axios";
let development = false;

export default axios.create({
   //baseURL: "https://staging.api.rouzo.co/api",//process.env.BASE_URL\
   //baseURL : development ? "http://127.0.0.1:9876/api" : "https://rouzopay.fly.dev/api",
   baseURL : development ? "http://127.0.0.1:9876/api" : "https://rouzopay-br1o.onrender.com/api",
   //baseURL : development ? "http://127.0.0.1:9876/api" : "https://rouzopay.onrender.com/api",
   headers: {
      "Content-Type": "application/json"
   },
   withCredentials : false,
   
})