import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";


let RightDashboardComponent = ({ children, title, setRightDashActive }) => {
    return <>
        <motion.div
            initial={{opacity : 0 ,right: -100}}
            animate={{opacity : 1, right : 0}}
            className="flex fixed h-screen z-30 font-lota lota-font  justify-end w-screen  bg-rouzo-overlay">
            <div className="flex flex-col gap-4 border-l-2 shadow-md border-rouzo-base-color bg-rouzo-deep-blue w-[70%] rounded-l-xl h-full ">
                <div className="flex flex-row">
                    <div className="">
                        <FontAwesomeIcon onClick={() => setRightDashActive(false)} className="hover:cursor-pointer text-3xl text-orange-300" icon={faCircleXmark}></FontAwesomeIcon>
                    </div>
                    <div className="flex flex-grow justify-center text-white py-2 px-4">
                        <p>{title ? title : "Placeholder Title"}</p>
                    </div>
                </div>

                {children}

            </div>
        </motion.div>
    </>
}

export default RightDashboardComponent;