import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TransactionPillComponent from "./TransactionPillComponent";
import { faInbox } from "@fortawesome/free-solid-svg-icons";


let TransactionLogComponent = ({ transactions }) => {
    return <>
        <div>
            {transactions && transactions.length > 0 ?
                transactions.map((transaction, index) => {
                    return <TransactionPillComponent index={index} transaction={transaction}></TransactionPillComponent>
                })      
                :
                <div className="flex justify-center py-16 items-center rounded-xl bg-rouzo-base-color opacity-10">
                    <div className="flex flex-col  text-rouzo-deep-blue ">
                        <FontAwesomeIcon className="h-10 opacity-100" icon={faInbox}></FontAwesomeIcon>
                        <p className="font-bold text-white opacity-100">No payment</p>
                    </div>
                </div>
            }

        </div>
    </>
}

export default TransactionLogComponent;