import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AdminContext } from "../context/AdminContext";
import CollectionPageService from "../api/services/CollectionPageService";

const useCollectionPage = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isAborted, setIsAborted] = useState(false);

    const [error, setError] = useState(null);
    const {userDetails, setUserDetail} = useContext(AdminContext);
    const {collectionPages, setCollectionPages} = useContext(AdminContext);
    const {transactions, setTransactions} = useContext(AdminContext)
    const {pageTransactions, setPageTransactions} = useContext(AdminContext)
    const token = localStorage.getItem("rouzo2.0_token");
    //should for fetch

    const fetchCollectionPage = async () => {

    }
    const fetchTransactions = async () => {

    }
    const fetchTransactionsForPage = async (pageSlugId) => {
        try{
            const res = await CollectionPageService.transaction.page.all(pageSlugId);
            const { type, errors, status } = res;
            console.log(res)
            if (type === "success") {
                let { data } = res;
                
                setPageTransactions(data);
            }
            if (type === "error") {
                toast.error(res.msg);
            }
            setIsLoading(false);

            if (!isAborted) {
                setIsLoading(false);
                setError(null);
            }
        }
        catch(e){
            toast.error(e)
            if (!isAborted) {
                console.log(e, "error");
                setIsLoading(false);
            }
        }
    }
    const fetchCollectionPages = async () => {
        try {
            const res = await CollectionPageService.all();
            const { type, errors, status } = res;
            console.log(res)
            if (type === "success") {
                let { data } = res;
                let filteredActive = data.filter(o => o.deleted == false);
                setCollectionPages(filteredActive);
            }
            if (type === "error") {
                toast.error(res.msg);
            }
            setIsLoading(false);

            if (!isAborted) {
                setIsLoading(false);
                setError(null);
            }
        } catch (error) {
            setIsLoading(false);
            //##TODO: ERROR TOAST
            //errorToast(error.message);
            console.log(error)
           toast.error(error)
            if (!isAborted) {
                console.log(error, "error");
                setIsLoading(false);
            }
        }
    }
    useEffect(() => {
        return () => {
            setIsAborted(true);
        };
    }, []);

    return {
        isLoading,
        error,
        fetchCollectionPages,
        fetchCollectionPage,
        fetchTransactionsForPage,
        fetchTransactions
        //banks
    };
};

export default useCollectionPage;
