import { useState } from "react";
import ModalRootComponent from "./ModalRootComponent";
import InputComponent from "../InputComponent";
import Constants from "../../constants/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import CollectionPageService from "../../api/services/CollectionPageService";
import { toast } from "react-toastify";


let ManualCollectionModalComponent = ({ collectionInfo, setActive }) => {


    let { amount, amountCollected, pageSlugId, collectionInterval } = collectionInfo;

    let [collectionAmount, setCollectionAmount] = useState(null);
    let [isLoading, setIsLoading] = useState(false);

    let handleManualCharge = async () => {

        if (isLoading) {
            console.log("Ongoing process");
            return;
        }
        setIsLoading(true);
        console.log(collectionAmount);
        console.log(pageSlugId)

        try {
            let charge = await CollectionPageService.manualCharge(pageSlugId, collectionAmount);
            if (charge.type == "success") {
                toast.success(`${Constants.nairaString}${collectionAmount} has been charged successfully`);
            }
            else {
                toast.error("Charge failed:" + charge.msg)
            }
        }
        catch (e) {
            console.log(e)
            if(e.response){
                if(e.response.data){
                    toast("An error occured while charging card " + e.response.data?.message)
                }
            }
            else{
                toast("An error occured while charging card");
            }
        }
        setIsLoading(false);
    }
    return <>
        <ModalRootComponent
            children={
                <div className="z20 m-2 mt-3 mulish-font rounded-2xl font-lota lota-font self-center p-6 pt-9 bg-white">

                    <div onClick={() => setActive(false)} className="hover:cursor-pointer text-rouzo-text-color-2 font-medium text-xl">
                        <p>{'x'} close</p>
                    </div>
                    <div className="mb-6">
                        <p className="text-rouzo-deep-blue font-bold text-xl md:text-[32px] mb-0">Manual Collection</p>
                        <div className="p-4">
                            <div className="flex-col flex gap-4 mb-3 p-2">
                                <p className="text-left mb-0 font-regular">Collection Page Id</p>
                                <div className="flex flex-col md:flex-row gap-4">
                                    <InputComponent disabled required placeholder={"Page Id"} value={pageSlugId}></InputComponent>

                                </div>
                                <div className="flex flex-col md:flex-row gap-4">
                                    <InputComponent onChange={(e) => setCollectionAmount(e.target.value)} type="number" required placeholder={"Collection Amount"}></InputComponent>

                                </div>
                            </div>
                            <hr></hr>
                            <div className="flex-col flex gap-4 p-2">
                                <p className="text-left font-regular">Payment Information</p>
                                <div className="flex flex-row justify-center items-center md:flex-row gap-4">
                                    <div className="flex flex-col font-bold text-md text-left justify-left gap-2">
                                        <p className="">Total Amount Collected</p>
                                        <p >Collection Interval</p>
                                        <p>Collection Amount</p>
                                    </div>
                                    <div className="flex flex-col font-regular text-xs justify-left gap-4">
                                        <p className="font-regular text-md">{Constants.nairaString} {amountCollected}</p>
                                        <p className="font-regular text-md">{collectionInterval}</p>
                                        <p className="font-regular text-md">{Constants.nairaString} {amount}</p>
                                    </div>
                                    {/*<InputComponent placeholder={"Last name"}></InputComponent>*/}
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="text-right flex justify-end ">
                        <div >
                            <button disabled={collectionAmount ? false : true}
                                onClick={() => handleManualCharge()}
                                className={`text-xl gap-2 flex flex-row items-center font-semibold ${isLoading ? "hover:cursor-not-allowed" : ""} ${collectionAmount ? "bg-rouzo-green-light" : "bg-rouzo-green"} text-white p-2 px-4 rounded-md`}>
                                
                                Proceed with Manual Charge
                                {isLoading ? <FontAwesomeIcon className="h-4 text-white" spinPulse={true} icon={faSpinner} spin /> : ""}
                            </button>

                        </div>
                    </div>
                </div>
            }
        ></ModalRootComponent>
    </>
}


export default ManualCollectionModalComponent;