import { useState } from "react";
import ModalRootComponent from "./ModalRootComponent";
import InputComponent from "../InputComponent";
import { toast } from "react-toastify";
import CollectionPageService from "../../api/services/CollectionPageService";
import LoadingComponent from "./LoadingComponent";
import useCollectionPage from "../../hooks/useCollectonPage";



let NewCollectionPageComponent = ({ setIsModalActive }) => {

    let [duration, setDuration] = useState(null);
    let [interval, setInterval] = useState("everyMonth");
    let [firstName, setFirstName] = useState(null);
    let [lastName, setLastName] = useState(null);
    let [emailAddress, setEmailAddress] = useState(null);
    let [amount, setAmount] = useState(0);
    let [mobileNumber, setMobileNumber] = useState(null);
    let [showLoadingComponent, setShowLoadingComponent] = useState(false);
    let [link, setLink] = useState(null);
    let [createCollectionPageSuccess, setCreateCollectionPageSuccess] = useState(null);
    let [showLoading, setShowLoading] = useState(null);
    let {fetchCollectionPages} = useCollectionPage();

    let handleCreate = async () => {
        if (!duration) {
            toast.error("Collection duration was not set");
            return;
        }
        if (!interval) {
            toast.error("Collection interval was not set");
            return;
        }
        if (!firstName || !lastName) {
            toast.error("Full name is non-existent");
            return;
        }
        if (!emailAddress) {
            toast.error("Email address is non-existent");
            return;
        }
        if (!mobileNumber) {
            toast.error("Mobile number was not set");
            return;
        }
        if (!amount && amount == 0) {
            toast.error("Amount has not been set");
            return;
        }
        console.log(duration);
        console.log(interval);
        console.log(emailAddress);
        console.log(mobileNumber);
        console.log(amount);


        setShowLoadingComponent(true);
        setShowLoading(true);
        setTimeout(async () => {
            try {
                let x = await CollectionPageService.create({
                    duration: duration,
                    emailAddress: emailAddress,
                    amount: amount,
                    firstName: firstName,
                    lastName: lastName,
                    interval: interval,
                    mobileNumber: mobileNumber
                });
                if (x.type == "success") {
                    setShowLoading(false);
                    setLink(x.data.link);
                    setCreateCollectionPageSuccess(true);
                    fetchCollectionPages();
                    toast.success(x.message);
                }
                else if (x.type == "error") {
                    setShowLoading(false);
                    setCreateCollectionPageSuccess(false);
                    toast.error(x.message);
                }
            }
            catch (e) {
                setShowLoading(false);
                setCreateCollectionPageSuccess(false);
                console.log(e);
                toast.error(e);
            }
        }, 3000);


    }

    let handleCloseLoadingComponent = () => {
        setShowLoadingComponent(false);
        /*setDuration(null);
        setAmount(null);
        setEmailAddress(null);
        setFirstName(null);
        setLastName(null);
        setInterval(null);*/
    }
    return <>
         
        <ModalRootComponent
            children={
                <div className="flex items-center justify-center">
                   {showLoadingComponent ? <LoadingComponent close={() => handleCloseLoadingComponent()} loading={showLoading} success={createCollectionPageSuccess} link={link}></LoadingComponent> : ""}
                    <div className="z20 m-2 mt-3 mulish-font rounded-2xl font-lota lota-font self-center p-6 pt-9 bg-white">
                        
                        <div onClick={() => setIsModalActive(false)} className="hover:cursor-pointer text-rouzo-text-color-2 font-medium text-xl">
                            <p>{'x'} close</p>
                        </div>
                        <div className="mb-6">
                            <p className="text-rouzo-deep-blue font-bold text-xl md:text-[32px] mb-6">Create Collection Page</p>
                            <div className="p-4">
                                <div className="flex-col flex gap-4 mb-3 p-2">
                                    <p className="text-left font-regular">Customer Information</p>
                                    <div className="flex flex-col md:flex-row gap-4">
                                        <InputComponent onChange={(e) => setFirstName(e.target.value)} required placeholder={"First name"}></InputComponent>
                                        <InputComponent onChange={(e) => setLastName(e.target.value)} required placeholder={"Last name"}></InputComponent>
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-4">
                                        <InputComponent onChange={(e) => setEmailAddress(e.target.value)} required placeholder={"Email address"}></InputComponent>
                                        <InputComponent onChange={(e) => setMobileNumber(e.target.value)} required type="number" placeholder={"Mobile number"}></InputComponent>
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="flex-col flex gap-4 p-2">
                                    <p className="text-left font-regular">Payment Information</p>
                                    <div className="flex flex-col justify-center md:flex-row gap-4">
                                        <InputComponent onChange={(e) => setAmount(e.target.value)} required type={"number"} placeholder={"Amount"}></InputComponent>
                                        {/*<InputComponent placeholder={"Last name"}></InputComponent>*/}
                                    </div>
                                    <div className="flex flex-col justify-between md:flex-row gap-4">
                                        <div className="flex flex-col">
                                            <p className="text-left text-xs">Interval</p>
                                            <select onChange={(e) => setInterval(e.target.value)} required className="border border-black rounded-md p-1">
                                                
                                            <option value="everyWeek">Every Week</option>
                                                <option value="everyTwoWeeks">Every 2 Weeks</option>
                                                <option value="everyFourWeeks">Every 4 Weeks</option>
                                                <option selected value="everyMonth">Every month</option>
                                                <option value="everyTwoMonths">Every 2 months</option>
                                                <option value="everyYear">Every Year</option>
                                            </select>
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="text-left text-xs">Duration (in months)</p>
                                            <InputComponent required onChange={(e) => setDuration(e.target.value)} type={"number"} placeholder={"Duration"}></InputComponent>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="text-right flex justify-end ">
                            <div >
                                <button onClick={() => handleCreate()} className="text-xl font-semibold bg-rouzo-green-light text-white p-2 px-4 rounded-md">Create Collection Page</button>

                            </div>
                        </div>
                    </div>
                </div>
            }
        >

        </ModalRootComponent>
    </>
}

export default NewCollectionPageComponent;