import LogoComponent from "../components/LogoComponent";
import Logo from "../assets/img/logo.svg";
import { useState } from "react";
import Constants from "../constants/Constants";
import PaymentService from "../api/services/PaymentService";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PaystackFrameComponent from "../components/PaystackFrameComponent";
import VerificationLoadingComponent from "../components/VerificationLoadingComponent";
import { faSpinner, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


let HomePage = () => {
    let [amount, setAmount] = useState(100);
    let [emailAddress, setEmailAddress] = useState("");
    let [mobileNumber, setMobileNumber] = useState("");
    let [reference, setReference] = useState(null);
    let [isLoading, setLoading] = useState(false);
    let [checkoutUrl, setCheckoutUrl] = useState(null);
    let [loadPaystackFrame, setLoadPaystackFrame] = useState(false);
    let [showVerificationLoading, setVerificationLoading] = useState(false);
    let [showVerificationPage, setVerificationPage] = useState(false);
    let [verificationSuccess, setVerificationSuccess] = useState(false);
    let handlePaystackFrameClose = () => {
        console.log("handling")
        setLoadPaystackFrame(false);

        setVerificationPage(true);
        setVerificationLoading(true);
        setTimeout(() => {
            handleVerifyPayment(reference);
        }, 3000)
    }

    let handleVerifyPayment = async (reference) => {
        let _ver = await PaymentService.verify(reference);
        console.log(_ver);
        if (_ver.type == "success") {
            let { success } = _ver.data;
            if (success) {
                toast.success(_ver.msg);
                setVerificationLoading(false);
                setVerificationSuccess(true);
            }
            else {
                toast.error(_ver.msg);
                setVerificationLoading(false);
                setVerificationSuccess(false);
            }

        }
        else {
            toast.error(_ver.msg);
            setVerificationLoading(false);
            setVerificationSuccess(false);
        }

    }
    let handleProceed = async (e) => {
        e.target.disabled = true;
        setLoading(true);

        setTimeout(async () => {
            console.log(e);
            try {
                let _pay = await PaymentService.initialize({
                    amount: amount,
                    email: emailAddress,
                    mobileNumber: mobileNumber
                })
                /* setTimeout(() => {
                     e.target.disabled = false;
                     setLoading(false)
                 }, 10000)*/
                console.log(_pay);
                if (_pay.type == "success") {
                    let { url, reference } = _pay.data;
                    toast.success("Redirecting to card gateway");
                    setReference(reference);
                    setCheckoutUrl(url);
                    setLoadPaystackFrame(true);

                }
                e.target.disabled = false;
                setLoading(false)
            }
            catch (err) {
                toast.error(err);
                e.target.disabled = false;
                console.log(err)
                setLoading(false)
            }
        }, 2000)


        console.log(emailAddress);
        console.log(mobileNumber);

    }
    return <>
        <div className="p-8 h-screen font-lota flex bg-rouzo-deep-blue justify-center place-self-center items-center">
            {
                loadPaystackFrame ? <div className="font-lota h-screen rounded-2xl absolute p-4 flex flex-col justify-center place-self-center z-10 dropShadow-2xl items-center">
                    <PaystackFrameComponent url={checkoutUrl} close={handlePaystackFrameClose} reference={reference}></PaystackFrameComponent>

                </div> : ""
            }


            <div>
                <p className="text-xs mb-8 font-semibold text-rouzo-base-color bg-white rounded-md p-2">! We would charge an authorization amount of {Constants.nairaString}50 then {Constants.nairaString}{amount} subsequently </p>
                <div className="border border-rouzo-deep-blue hover:animate-none motion-safe:animate-pulse drop-shadow-rouzo-tran-shad bg-gradient-to-tr from-rouzo-green-light to-rouzo-deep-color  p-5 w-full rounded-3xl " >
                    <div className="flex flex-row justify-between mb-4">
                        <div className="flex flex-row items-center">
                            <img className="h-6" src={Logo}></img>
                            <p className="text-md font-bold  lota-font text-rouzo-base-color">Pay</p>
                        </div>
                        <div>
                            <p className="text-white font-bold text-base">{Constants.nairaString} {amount}</p>
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-col justify-center gap-2">
                            <div className=" flex flex-col justify-center gap-2">
                                <input onChange={(e) => setEmailAddress(e.target.value)} placeholder="email address" className="p-1 px-4 rounded-md"></input>
                                <input onChange={(e) => setMobileNumber(e.target.value)} placeholder="mobile number" className="p-1 px-4 rounded-md"></input>
                            </div>
                            <button onClick={(e) => handleProceed(e)} className="flex flex-row justify-center gap-4 items-center bg-gradient-to-tr from-rouzo-deep-blue to-rouzo-base-color p-3 rounded-md text-white">
                                Proceed{isLoading ? <FontAwesomeIcon className="h-4 text-rouzo-light-blue" spinPulse={true} icon={faSpinner} spin /> : ""}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {
                showVerificationPage ? <VerificationLoadingComponent close={() => setVerificationPage(false)} loading={showVerificationLoading} success={verificationSuccess}></VerificationLoadingComponent> : ""
            }


        </div>
    </>
}

export default HomePage;