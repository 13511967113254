

let InputComponent = (props) => {
    let {placeholder, onChange} = props;
    return <>
        <div>
            <input onChange={onChange} {...props} placeholder={placeholder} className="p-1 px-4 bg-rouzo-light-blue-2 rounded-md"></input>
        </div>
    </>
}

export default InputComponent;