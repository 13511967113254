

let PaystackFrameComponent = ({url, reference, close}) => {
    return <>
        <div className="font-lota h-screen rounded-2xl p-4 flex flex-col z-50 justify-center place-self-center dropShadow-2xl items-center">
            <iframe className="h-full rounded-2xl relative z-0" src={url}>

            </iframe>
            <p onClick={close} className="absolute bottom-10 bg-rouzo-deep-blue rounded-t-2xl z-20 py-1 px-4 text-rouzo-green-light font-bold text-xs text-underline underline underline-offset-3">Close & Verify</p>
        </div>
    </>
}

export default PaystackFrameComponent