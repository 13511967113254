import LogoComponent from "../components/LogoComponent";
import Logo from "../assets/img/logo.svg";
import NewCollectionPageComponent from "../components/modals/NewCollectionPageComponent";
import { useContext, useEffect, useState } from "react";
import CollectionPageListComponent from "../components/CollectionPageListComponent";
import { AdminContext } from "../context/AdminContext";
import useCollectionPage from "../hooks/useCollectonPage";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import CollectionPageInfoComponent2 from "../components/dashboard/CollectionPageInfoComponent2";
import RightDashboardComponent from "../components/dashboard/RightDashboardComponent";

let AdminDashboardPage = () => {
    let { collectionPages, setCollectionPages } = useContext(AdminContext);
    let { fetchCollectionPages, fetchTransactionsForPage } = useCollectionPage();
    let [selectedCollectionPage, setSelectedCollectionPage] = useState(null);
    let [showMoreInfoOnCollectionPage, setShowMoreInfoOnCollectionPage] = useState(false);
    let [rightDashActive, setRightDashActive] = useState(false);
    

    let handleMoreInfo = (pageSlugId) => {
        fetchTransactionsForPage(pageSlugId);
        setShowMoreInfoOnCollectionPage(false);
        setSelectedCollectionPage(null);
        setTimeout(() => {
            if (collectionPages) {
                let page = collectionPages.filter(o => o.pageSlugId == pageSlugId);
                if (page) {
                    setSelectedCollectionPage(page[0]);
                    //console.log("Found it");
                    //console.log(page)
                    setShowMoreInfoOnCollectionPage(true);
                }
                else {
                    //console.log("Page not found");
                    toast.error("Collection Page not found");
                }
            }
        }, 50)

    }
    useEffect(() => {
        if (!collectionPages) {
            fetchCollectionPages()
        }
    }, [])
    let [isCreateCollectionPageModalActive, setIsCreateCollectionPageModalActive] = useState(false);
    return <>
        {isCreateCollectionPageModalActive ? <NewCollectionPageComponent setIsModalActive={setIsCreateCollectionPageModalActive}></NewCollectionPageComponent> : ""}
        {showMoreInfoOnCollectionPage ? <CollectionPageInfoComponent2 setShowMoreInfoOnCollectionPage={setShowMoreInfoOnCollectionPage} pageData={selectedCollectionPage}></CollectionPageInfoComponent2> : ""}
        <div className="flex h-screen overflow-y-auto font-lota lota-font gap-4 bg-rouzo-deep-blue">
            <div className="flex hidden md:block py-6 pr-2 pt-7 flex-col">
                <div className=" flex flex-row justify-center mb-3">
                    <img className="h-14" src={Logo}></img>
                </div>
                <div className="flex flex-col gap-2 p-6 bg-white rounded-r-xl">
                    <div className="bg-rouzo-deep-blue rounded-md p-3">
                        <p className="p-1 text-base text-rouzo-base-color">Collection Payment Page</p>
                    </div>
                    <div className="bg-rouzo-deep-blue rounded-md p-3">
                        <p className="p-1 text-base text-rouzo-base-color">Users</p>
                    </div>
                </div>
            </div>
            <div className="flex p-6 pt-10 flex-col flex-grow gap-8">
                <div className="flex-col flex">
                    <p className="text-3xl text-left font-bold text-white ">Collection Payment Pages</p>
                    <br>
                    </br>
                    <div className="flex flex-col md:flex-row  items-center gap-6">
                        <div className="flex flex-col md:flex-row gap-8">
                            <div className="px-10 py-6 bg-white rounded-md">
                                <p className="text-4xl ">{collectionPages ? collectionPages.length : 0}</p>
                                <p className="">Total Collection Pages</p>
                            </div>
                            <div className="border border-2 border-rouzo-green-light px-10 py-6 bg-white rounded-md">
                                <p className="text-4xl text-rouzo-green-light">{collectionPages ? collectionPages.filter(o => o.verified).length : 0}</p>
                                <p className="">Total Active Collection Pages</p>
                            </div>
                        </div>
                        <div className="flex justify-center items-center">
                            <div>
                                <button onClick={() => setIsCreateCollectionPageModalActive(true)} className="text-xl animate-pulse font-semibold bg-rouzo-green-light text-white p-2 px-4 rounded-md">Create Collection Page</button>
                            </div>
                            <p onClick={() => setRightDashActive(true)}>right</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row  gap-4">
                    <div className="flex flex-col ">
                        <p className="text-left text-white text-2xl font-semibold">Collection Page History</p>
                        <br></br>
                        <div>
                            <CollectionPageListComponent  moreFn={handleMoreInfo}></CollectionPageListComponent>
                        </div>
                    </div>
                    <div className="flex flex-row flex-grow  justify-center">


                    </div>
                </div>


            </div>

        </div>

    </>
}


export default AdminDashboardPage;