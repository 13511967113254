import { useContext, useEffect, useState } from "react";
import CollectionPageService from "../api/services/CollectionPageService";
import CollectionPagePillComponent from "./CollectionPagePillComponent";
import useCollectionPage from "../hooks/useCollectonPage";
import { AdminContext } from "../context/AdminContext";



let CollectionPageListComponent = ({moreFn}) => {
    let {fetchCollectionPages} = useCollectionPage();
    let {collectionPages, setCollectionPages} = useContext(AdminContext);
  

    useEffect(() => {
        if(!collectionPages){
            console.log("checking......")
            fetchCollectionPages();
            
        }
        
    }, [collectionPages])
    //console.log(collectionPages)
    return <>

        <div>
            <div className="flex pl-4 px-2 text-rouzo-base-color font-bold justify-between gap-6 flex-row">
                <div className="text-xs ">
                    <p>S/N</p>
                </div>
                <div className="text-xs ">
                    <p>Name</p>
                </div>
                <div className="text-xs ">
                    <p>Contact Details</p>
                </div>
                <div className="text-xs ">
                    <p>Page status</p>
                </div>
                <div className="text-xs ">
                    <p>Amount</p>
                    <p>Amount Collected</p>
                </div>
                <div className="text-xs mr-6 pr-6">
                    <p>Link</p>
                </div>
                
            </div>
            {collectionPages && collectionPages.length > 0 ? 
                collectionPages.map((page, index) => {
                    if(page.deleted == false){
                        return <CollectionPagePillComponent key={page.pageSlugId} moreFn={moreFn} index={index + 1} data={page}></CollectionPagePillComponent>
                    }
                })
            : ""}

        </div>
    </>
}


export default CollectionPageListComponent;