import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faSpinner, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalRootComponent from "./ModalRootComponent";


let LoadingComponent = ({ loading, success, close, link }) => {
    return <>
        <ModalRootComponent
            children={
                <div className="p-10  z-40 ">
                    <div className="flex-col flex gap-4 bg-white p-10 rounded-2xl shadow-2xl">
                        {loading ? <div className="lota-font">
                            <FontAwesomeIcon className="h-10 text-rouzo-light-blue" spinPulse={true} icon={faSpinner} spin />
                            <br></br>
                            <p className="text-rouzo-base-color font-bold animate-pulse">Loading...</p>
                        </div> :
                            <div className="lota-font flex flex-col text-base font-bold text-rouzo-base-color">
                                {success ? <FontAwesomeIcon className="text-rouzo-green-light h-10" icon={faCircleCheck}></FontAwesomeIcon> : <FontAwesomeIcon className="text-red-400 h-10" icon={faTriangleExclamation}></FontAwesomeIcon>}
                                <br>
                                </br>
                                {success ? <p className="text-rouzo-green-light">Collection page created Successfully!</p> : <p className="text-red-400">Oops! <br></br>creation of payment page failed.</p>}
                                {link ? <a href={link} target="_blank" className="text-rouzo-green-light underline underline-offset-3">{link}</a> : ""}
                            </div>
                        }
                        <span onClick={close} className="underline relative px-1 py-1 rounded-t-md text-white bg-rouzo-deep-blue -bottom-10 hover:cursor-pointer underline-offset-2 font-bold ">Close</span>

                    </div>
                </div>
            }>

        </ModalRootComponent>
    </>
}


export default LoadingComponent;