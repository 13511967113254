import { Outlet } from "react-router-dom";
import AdminProvider from "../context/AdminContext";
import { ToastContainer } from "react-toastify";

const AdminLayout = ({children}) => {
    return (
        <main className="">
            {children ? children : <AdminProvider>
                <ToastContainer/>
                <Outlet />
            </AdminProvider>}
        </main>
    )
}

export default AdminLayout;