

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Routes
} from "react-router-dom";

import Layout from "../layouts/Layout";
import HomePage from "../pages/HomePage";
import AdminDashboardPage from "../pages/AdminDashboardPage";
import CollectionPage from "../pages/CollectionPage";
import AdminLayout from "../layouts/AdminLayout";


const homeRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/p/:pageSlugId" element={<CollectionPage />}></Route>
        <Route element={< AdminLayout />}>
          <Route path="/admin/dashboard" element={<AdminDashboardPage />}></Route>
        </Route>
      </Route>

    </Route>

  )
);


export default homeRouter;
