import Constants from "../../constants/Constants";
import axios from "../axios";
const PaymentService = {
    initialize: async (data) => {
        let url = Constants.PAY.INITIATE;
        let req = await axios.post(url,data);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    initializeCollection : async (pageSlugId, data) => {
        let url = Constants.PAY.INITIATE_COLLECTION(pageSlugId);
        let req = await axios.post(url,data);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    verify: async (reference) => {
        console.log(reference);
        let url = Constants.PAY.VERIFY(reference);
        let req = await axios.post(url,{});
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    }
};

export default PaymentService;
