import { useContext, useEffect, useState } from "react";
import RightDashboardComponent from "./RightDashboardComponent";
import Constants from "../../constants/Constants";
import { Link } from "react-router-dom";
import { AdminContext } from "../../context/AdminContext";
import useCollectionPage from "../../hooks/useCollectonPage";
import TransactionLogComponent from "../transaction/TransactionLogComponent";
import ManualCollectionModalComponent from "../modals/ManualCollectionModalComponent";
import CollectionPageService from "../../api/services/CollectionPageService";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faDeleteLeft, faEdit, faSpinner, faTrashArrowUp, faX } from "@fortawesome/free-solid-svg-icons";
import { isEditable } from "@testing-library/user-event/dist/utils";
import InputComponent from "../InputComponent";



let CollectionPageInfoComponent2 = ({ setShowMoreInfoOnCollectionPage, pageData }) => {
    let { amount, amountCollected, pageSlugId, nextCollectionTime, duration, collectionInterval, createdAt, firstName, lastName } = pageData;
    let [newDuration, setDuration] = useState(null);
    let [interval, setInterval] = useState("everyMonth");
    //let [firstName, setFirstName] = useState(null);
    //let [lastName, setLastName] = useState(null);

    let [editEnabled, setEditEnabled] = useState(false);
    let { pageTransactions, setPageTransactions } = useContext(AdminContext);
    let { fetchCollectionPages, fetchTransactionsForPage } = useCollectionPage();
    let [isManualCollectionModalActive, setIsManualCollectionModalActive] = useState(false);

    let [isDeleteLoading, setIsDeleteLoading] = useState(false);
    let [isUpdateTimeLoading, setIsUpdateTimeLoading] = useState(false);

    let [newNextCollectionTime, setNewNextCollectionTime] = useState(null);

    let handleUpdateCollectionTime = async () => {
        //setNewNextCollectionTime(new Date(e.target.value));
        //console.log(e.target.value)
        if (isUpdateTimeLoading) {
            return;
        }

        setIsUpdateTimeLoading(true);

        setTimeout(async () => {
            try {
                let updateRequest = await CollectionPageService.updateCollectionTime(pageSlugId, newNextCollectionTime);

                if (updateRequest.success) {
                    fetchCollectionPages();
                    setEditEnabled(false);
                    toast.success(updateRequest.msg)
                }
                else {
                    toast.error(updateRequest.msg)
                }
            }
            catch (e) {
                console.log(e);
                toast.error(e)
            }
            setIsUpdateTimeLoading(false);
        }, 2000)
    }

    let disableCollectionPage = async () => {
        if (isDeleteLoading) {
            return
        }
        setIsDeleteLoading(true);
        setTimeout(async () => {
            try {
                let deleteRequest = await CollectionPageService.delete(pageSlugId);

                if (deleteRequest.success) {
                    fetchCollectionPages();
                    toast.success(deleteRequest.msg)
                }
                else {
                    toast.error(deleteRequest.msg)
                }

                setIsDeleteLoading(false);
            }
            catch (e) {
                console.log(e);
                toast.error(e);
            }
            setIsDeleteLoading(false);
        }, 2000)

    }

    useEffect(() => {
        if (!pageTransactions) {
            fetchTransactionsForPage(pageSlugId)
        }
        else {
            console.log(pageTransactions)
        }
    }, [])
    return <>
        <RightDashboardComponent
            setRightDashActive={setShowMoreInfoOnCollectionPage}
            title={`Collection Page - ${pageSlugId}`}
            children={
                <div>
                    {isManualCollectionModalActive ? <ManualCollectionModalComponent setActive={setIsManualCollectionModalActive} collectionInfo={pageData}></ManualCollectionModalComponent> : ""}
                    <div className="flex flex-col flex-grow justify-between gap-4">

                        <div className="flex-grow flex flex-col ">
                            <div className="flex flex-col gap-2 justify-between px-8">
                                <p className="text-xl font-bold text-white"> {lastName}  {firstName}</p>
                                <div className="border flex flex-col gap-4 flex-grow border-rouzo-deep-blue hover:animate-none drop-shadow-rouzo-tran-shad bg-gradient-to-tr from-rouzo-deep-blue py-8 to-rouzo-base-color  p-5 rounded-3xl " >
                                    <div className="text-white text-left">
                                        <p className="text-xs">Amount Collected</p>
                                        <p className="text-4xl font-bold">{Constants.nairaString} {amountCollected}</p>
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-2 items-center">
                                        <div className="text-left text-xs ">
                                            <span className="py-[2px] font-bold rounded-full bg-white px-2  text-rouzo-base-color">{Constants.nairaString}{amount} {interval}</span>
                                        </div>
                                        <div className="text-left text-xs py-[2px] flex flex-row gap-2 items-center font-bold rounded-md md:rounded-full bg-white px-2  text-rouzo-base-color">
                                            {editEnabled ?
                                                <div className="flex flex-col md:flex-row items-center">
                                                    <InputComponent onChange={(e) => setNewNextCollectionTime(new Date(e.target.value))} className="text-xs" placeholder="date format: 2024/mm/dd"></InputComponent>
                                                    <div className="flex flex-row gap-2">
                                                        <div onClick={() => handleUpdateCollectionTime()} className="flex items-center flex-row bg-rouzo-green-light gap-1 px-2 hover:cursor-pointer rounded-full text-white ">
                                                            {!isUpdateTimeLoading ?
                                                                <FontAwesomeIcon className="h-2 text-white" icon={faCheckCircle}></FontAwesomeIcon>
                                                                :
                                                                <FontAwesomeIcon className="h-2 text-white" spin={true} icon={faSpinner}></FontAwesomeIcon>
                                                            }
                                                            <p>Save</p>
                                                        </div>
                                                        <div onClick={() => setEditEnabled(false)} className="flex items-center flex-row bg-red-400 gap-1 px-2 hover:cursor-pointer rounded-full text-white ">
                                                            <FontAwesomeIcon className="h-2 text-white" icon={faX}></FontAwesomeIcon>
                                                            <p>Cancel</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <span className="">{nextCollectionTime ? new Date(nextCollectionTime).toDateString() : "Collection time not set"}</span>
                                                    <span onClick={() => setEditEnabled(true)} className=" hover:cursor-pointer hover:text-rouzo-base-color text-black">
                                                        <FontAwesomeIcon icon={faEdit} className=" h-4"></FontAwesomeIcon>
                                                    </span>
                                                </>
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className="flex flex-col md:flex-row gap-2 justify-center ">
                                    <div onClick={() => setIsManualCollectionModalActive(true)} className="bg-rouzo-green-light px-3  py-1 text-xs rounded-md text-white font-bold hover:cursor-pointer hover:border-2 hover:border-white ">
                                        <p>Manual Collection</p>
                                    </div>
                                    <div onClick={() => disableCollectionPage()} className="flex flex-row gap-2 text-center items-center bg-red-400 px-3 text-xs  py-1  rounded-md text-white font-bold hover:cursor-pointer hover:border-2 hover:border-white ">
                                        <FontAwesomeIcon className="h-4 text-white " icon={faTrashArrowUp}></FontAwesomeIcon>
                                        <p>Delete Collection Page</p>
                                        {isDeleteLoading ? <FontAwesomeIcon spin={true} icon={faSpinner} className="h-4 text-white"></FontAwesomeIcon> : ""}
                                    </div>

                                </div>
                            </div>
                            <br></br>
                            <div className="text-white px-8">
                                <p className="text-left font-bold text-xl">Payment Log</p>
                                <br></br>
                                <TransactionLogComponent transactions={pageTransactions} ></TransactionLogComponent>
                            </div>

                        </div>
                        <div className="relative bottom-0 font-bold text-rouzo-deep-blue hover:cursor-pointer bg-rouzo-green-light px-4 rounded-b-xl text-xs underline underline-offset-2">
                            <Link target="_blank" to={`http://${window.location.host}/p/${pageSlugId}`}>Go to page</Link>

                        </div>
                    </div>
                </div>
            }
        >

        </RightDashboardComponent>
    </>
}


export default CollectionPageInfoComponent2;